var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"d-flex flex-column justify-center text-center pt-7 pb-4",class:{ 'rounded-10': !_vm.item.startDate },style:({ border: _vm.item.startDate ? '' : '1px solid #FFFFFF' })},[_c('v-icon',{staticClass:"mr-1 align-center",class:{
        'teal--text text--accent-3': hover,
        'white--text': !hover
      },attrs:{"size":"28"}},[_vm._v(" "+_vm._s(_vm.item.startDate ? 'mdi-calendar-range' : 'mdi-plus')+" ")]),_c('span',{staticClass:"mt-4 font-weight-600 subtitle-2 align-center pb-2",class:{
        'teal--text text--accent-3': hover,
        'white--text': !hover
      }},[_vm._v(" "+_vm._s(_vm.item.startDate ? _vm.fullDateMonth(_vm.item.startDate) : 'Lisa ise')+" ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }