<template>
  <v-dialog v-model="addNewEventDialog" max-width="432px">
    <v-card>
      <v-card-title class="body-1">
        Lisa uus üritus
        <v-spacer></v-spacer>
        <v-btn icon small @click="addNewEventDialog = false">
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card class="transparent elevation-0">
        <v-card-text>
          <v-form>
            <v-row>
              <v-col cols="12" class="d-flex justify-center">
                <img :src="require('@/assets/img/ticket.png')" alt="" />
              </v-col>
              <v-col>
                <v-dialog
                  ref="dateMenu"
                  v-model="dateMenu"
                  :return-value.sync="date"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="date"
                      :rules="dateRules"
                      label="Toimumiskuupäev"
                      append-icon="mdi-calendar"
                      readonly
                      outlined
                      @click:append="dateMenu = !dateMenu"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date" no-title scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="dateMenu = false">
                      Tühista
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dateMenu.save(date)"
                    >
                      Sisesta
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
                <v-dialog
                  ref="timeMenu"
                  v-model="timeMenu"
                  :return-value.sync="time"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="time"
                      label="Toimumisaeg"
                      append-icon="mdi-clock-outline"
                      readonly
                      outlined
                      @click:append="timeMenu = !timeMenu"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-if="timeMenu"
                    v-model="time"
                    format="24hr"
                    full-width
                    @click:minute="$refs.timeMenu.save(time)"
                  ></v-time-picker>
                </v-dialog>
                <v-text-field
                  id="venue"
                  v-model="venue"
                  :rules="venueRules"
                  name="venue"
                  label="Toimumiskoht"
                  append-icon="mdi-map-marker"
                  outlined
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!date || !time || !venue"
          color="primary darken-3"
          :loading="addingEvent"
          @click="addNewEvent()"
        >
          Lisa
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from 'vuex'
import { uuid } from 'uuidv4'

export default {
  name: 'AddDateVenueDialog',
  props: {
    event: {
      type: Object,
      required: true
    },
    visible: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      venue: '',
      venueRules: [v => v.length >= 1 || 'Toimumiskoht on kohustuslik!'],
      date: '',
      dateRules: [v => v.length >= 1 || 'Toimumiskuupäev on kohustuslik!'],
      time: '',
      timeRules: [v => v.length >= 1 || 'toimumisaeg on kohustuslik!'],
      dateMenu: false,
      addingEvent: false,
      timeMenu: false
    }
  },
  computed: {
    addNewEventDialog: {
      get() {
        return this.visible
      },
      set(value) {
        if (!value) {
          this.$emit('close')
        }
      }
    }
  },
  methods: {
    ...mapActions('events', ['getAllEvents', 'createPost']),
    async addNewEvent() {
      if (!this.date || !this.venue) return
      this.addingEvent = true

      const newEvent = {
        ...this.event,
        id: uuid(),
        venue: this.venue,
        startDate: new Date(`${this.date}T${this.time}`).toISOString()
      }

      this.addingEvent = false
      this.addNewEventDialog = false
      this.$store.commit('events/setSelectedEvent', newEvent)
      this.$parent.$emit('AddDateVenueDialog', newEvent)
    }
  }
}
</script>
