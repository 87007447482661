<template>
  <v-hover v-slot:default="{ hover }">
    <div
      class="d-flex flex-column justify-center text-center pt-7 pb-4"
      :class="{ 'rounded-10': !item.startDate }"
      :style="{ border: item.startDate ? '' : '1px solid #FFFFFF' }"
    >
      <v-icon
        class="mr-1 align-center"
        size="28"
        :class="{
          'teal--text text--accent-3': hover,
          'white--text': !hover
        }"
      >
        {{ item.startDate ? 'mdi-calendar-range' : 'mdi-plus' }}
      </v-icon>
      <span
        class="mt-4 font-weight-600 subtitle-2 align-center pb-2"
        :class="{
          'teal--text text--accent-3': hover,
          'white--text': !hover
        }"
      >
        {{ item.startDate ? fullDateMonth(item.startDate) : 'Lisa ise' }}
      </span>
    </div>
  </v-hover>
</template>

<script>
import FormatDate from '@/mixins/FormatDate'

export default {
  name: '',
  mixins: [FormatDate],
  props: {
    item: {
      type: Object,
      required: false,
      default: () => {}
    }
  }
}
</script>
